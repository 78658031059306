import Translations from './Translations';
import * as deDE from './de-DE.json';
import * as frFR from './fr-FR.json';
import * as en from './en.json';
import * as enUS from './en-US.json';
import i18n from './config';

// prettier-ignore
Translations
  .local('en', en.default)
  .local('de-DE', deDE.default)
  .local('fr-FR', frFR.default)
  .local('en-US', enUS.default)
  .register(['AddressForm'])
  .register(['AddressDialog'])
  .register(['AddressArea'])
  .register(['Upload'])
  .register(['AssemblyCalculator'])
  .register(['customConfirmation'])
  .register(['project', 'ProjectSpecCard'])
  .register(['project', 'ProjectCards'])
  .register(['project', 'ProjectCardsContainer'])
  .register(['project', 'ProjectShowcaseCards'])
  .register(['project', 'RevisionButton'])
  .register(['project', 'RevisionList'])
  .register(['project', 'ProjectCheckoutButton'])
  .register(['project', 'ProjectQuickLink'])
  .register(['project', 'SharingModalContent'])
  .register(['order', 'Checkout'])
  .register(['order', 'CheckoutSelection'])
  .register(['order', 'CountrySelect'])
  .register(['order', 'SepaInfoDialog'])
  .register(['order', 'PhoneNumberDialog'])
  .register(['BoardInspectorControls'])
  .register(['BoardInspectorLegend'])
  .register(['bom', 'AssemblyImpactNote'])
  .register(['bom', 'BomEditor'])
  .register(['bom', 'BomPartTable'])
  .register(['bom', 'Menu'])
  .register(['bom', 'PriceTable'])
  .register(['bom', 'RunningOrderNote'])
  .register(['bom', 'StatusNote'])
  .register(['bom', 'StockIndicator'])
  .register(['bom', 'part_search', 'CreateBomPartsTab'])
  .register(['bom', 'part_search', 'PartSearch'])
  .register(['bom', 'part_search', 'PhysicalPartList'])
  .register(['bom', 'part_search', 'SmartMatch'])
  .register(['support', 'Support'])
  .register(['support', 'SupportForm'])
  .register(['support', 'SupportTopicPicker'])
  .register(['order', 'OrdersContainer'])
  .register(['order', 'OrderCard'])
  .register(['quotations', 'QuotationsContainer'])
  .register(['quotations', 'QuotationCard'])
  .register(['quotations', 'NewQuotationCard'])
  .register(['quotations', 'SharingModal'])
  .register(['invoices', 'InvoicesContainer'])
  .register(['invoices', 'InvoiceCard'])
  .register(['dispatch', 'Dispatcher'])
  .register(['dispatch', 'Checkin'])
  .register(['dispatch', 'CheckinBoards'])
  .register(['dispatch', 'DispatchHistory'])
  .register(['StockSlotCard'])
  .register(['boards', 'BoardNotesBar'])
  .register(['boards', 'AssemblyBar'])
  .register(['boards', 'AssemblyUnavailableOverlay'])
  .register(['boards', 'Guide'])
  .register(['assembly', 'Guide'])
  .register(['assembly', 'Material'])
  .register(['assembly', 'OrderIndex'])
  .register(['helper', 'Positions'])
  .register(['WebGlViewer']);

const components = Translations.components;
export default Translations.components;
export { i18n, components };
