import _ from 'lodash';

class Translations {
  constructor() {
    this.locals = {};
    this.components = {};

    this.local = this.local.bind(this);
  }

  local(name, module) {
    if (this.locals[name]) {
      throw new Error(`The name ${name} already exists`);
    }

    this.locals[name] = module;

    return this;
  }

  isNotRegistered(path) {
    return Object.entries(this.locals)
      .filter(([k, v]) => {
        if (typeof _.get(v, path) === 'undefined') {
          return k;
        }
        return undefined;
      })
      .flatMap((v) => v[0]);
  }

  createMessages(path) {
    return Object.entries(this.locals).reduce((acc, curr) => {
      const [localKey, module] = curr;
      acc[localKey] = _.get(module, path);
      return acc;
    }, {});
  }

  register(componentName) {
    const path = componentName.join('.');
    const errors = this.isNotRegistered(path);

    if (errors.length > 0) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(`The component translation for ${path} is not registered for locale "${errors.join(', ')}"`);
      }
    }

    const messages = {
      messages: this.createMessages(path),
    };

    _.set(this.components, path, messages);

    return this;
  }
}

export default new Translations();
