import Vue from 'vue';
import VueI18n from 'vue-i18n';
import dateTimeFormats from './dateTimeFormats';

Vue.use(VueI18n);

const i18n = (translations = {}) => new VueI18n({
  locale: document.body.dataset.locale,
  fallbackLocale: 'en',
  messages: translations.messages,
  dateTimeFormats,
});

export default i18n;
